<template>

  <div class="section-content download-reports-history-wrapper">
    <div style="flex: 1">
      <hb-report
        :key="report_key"
        :report_id="report_id"
        :report_type="'reports_download_history'"
        :column-conf="columnConf"
        :grid-conf="gridConf"
        :actions_panel="['filters']"
        right_click
        row_click
        show_search
        class="mr-4"
        searchBarWidth="525px"
        searchLeftAligned
      ></hb-report>
    </div>
  </div>
</template>

<script type="text/babel">
import HbReport from '../assets/HummingbirdReportViewer.vue';
import { EventBus } from "../../EventBus.js";
import ClickCellRenderer from "@/components/BI/ClickCellRenderer.vue";
import MultiLineRenderer from "@/components/BI/MultiLineRenderer.vue";
import { downloadFile } from '../../utils/common'
import api from '../../../src/assets/api.js'
import ReportsDownloadHistoryActions from './ReportsDownloadHistoryActions.vue';
export default {
    name: "ReportsDownloadHistory",
    data() {
            return {
                report_key: 0,
                view: '',
                report_id: '',
                report_accounting_export:
                    {
                        name: "Accounting Export",  key: 'accounting_exports', type: "accounting_exports", description: "Accounting Export history" 
                    },
                
            }
    },
    components:{
        HbReport,
        ClickCellRenderer,
        MultiLineRenderer,
    },
    mounted() {
      let events = {
        downloadHistoryReport: this.downloadReport,
      };
      EventBus.$on("reportsDownloadEvents", ({ event, data }) =>
        events[event](data, event)
      );
    },
    destroyed() {
        EventBus.$off('reportsDownloadEvents');
    },
    computed: {
      columnConf() {
        return {
          reports_download_upload_id: {
            filter: false,
            cellRenderer: 'ClickCellRenderer',
            cellRendererParams: {
                type: "link",
                formatter: (value) => (value ? "Download" : ""),
                event: {
                    name: "clickDownloadButton",
                    method: "onClickDownload",
                },
            },
          },
          reports_download_properties: {
            cellRenderer: 'MultiLineRenderer',
            cellRendererParams: {
                fields: [
                  {
                    column: "reports_download_properties",
                    formatter: (value) => 
                      `${value?.length > 0? value.length : 'No'} ${value?.length > 1? 'Properties' : 'Property'} `,
                    customClass: () => "dashed-underline",
                    tooltip: true,
                    tooltipFormatter: (value) =>
                      value?.length > 0 ? value?.map(v => `<div class="formatted-text">${v}</div>`).join("") : 'No Property',
                  },
                ]
            },
          },
          reports_download_recipients: {
            cellRenderer: 'MultiLineRenderer',
            cellRendererParams: {
                fields: [
                  {
                    column: "reports_download_recipients",
                    formatter: (value) => 
                      `${value?.length > 0? value.length : 'No'} ${value?.length > 1? 'Recipients' : 'Recipient'} `,
                    customClass: () => "dashed-underline",
                    tooltip: true,
                    tooltipFormatter: (value) =>
                      value?.length > 0 ? value?.map(v => `<div class="formatted-text">${v}</div>`).join("") : 'No Recipient',
                  },
                ]
            },
          },
        }
      },
      gridConf() {
        return {
          props: {
            BIHelpers: {
              kebab: {
                enabled: true,
                icon: "mdi-dots-vertical",
                component: {
                  definition: ReportsDownloadHistoryActions,
                },
                action: "click",
              },
            },
            overlayNoRowsTemplate:
              '<span style="font-size: 20px;padding: 10px;">No Reports</span>',
          },
        };
      },
    },
    methods: {
      async downloadReport(data) {
        console.log(data);
        if (!data?.reports_download_upload_id) return;
        const { reports_download_upload_id } = data;
        const query_params = data.reports_download_delivery_method !== "On Demand"? { shared_report: true } : null;
        const response = await api.get(
          this.$app,
          `${api.REPORTS}generate-presigned-url/${reports_download_upload_id}`,
          query_params
        );
        downloadFile({ url: response.url });
      }
    }
};
</script>

<style scoped>
  .section-content {
    display: flex;
    flex-direction: column;
    outline: 0;
    width: 100%;
    height: 100%;
    padding: 2px 20px 10px 0 !important;
  }
</style>

<style>
  .download-reports-history-wrapper .v-divider.space-tabs-bottom-divider {
    border: none;
  }
</style>