<template>

    <v-menu
            v-model="menu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="200px"
            height="20px"
            class="report-date-picker-container"
    >
        <template v-slot:activator="{ on }">

            <v-text-field
              :hide-details="message && !message.length"
              :value="date | formatDateServices"
              :label="label"
              :clearable="!!clearable"
              :clear-icon="clearIcon"
              readonly
              v-on="on"
              :solo="defaultValue"
              flat
              Boolean
              outlined
              :style="'height:10px; font-size:16px'"
              :dense="dense"
              :error="error"
              :error-messages="message"
              :placeholder="placeholder"
              @click:clear="setValue(null)"
              class="hummingbird-date-text-field-wrapper"
              :class="{'pt-1': todo, 'adjust-label': adjustLabel, 'pa-0 ma-0' : removePaddingMargin,'adjust-for-contact-details' : adjustForContactDetails}"
              @click="$emit('clicked')"
              @focus="$emit('onfocus')"
             
            >
                <template v-slot:prepend-inner>
                    <hb-icon style="margin-top: -1px;" small @click="menu = true">mdi-calendar-blank</hb-icon>
                </template>
            </v-text-field>
            {{errors.first('hb-date-picker')}}
        </template>
        <v-date-picker
          no-title
          v-model="date"
          @change="setValue"
          @input="$emit('onblur')"
          show-current
          :max="max ? max : ''"
          :min="min ? min : ''"
        >
        </v-date-picker>

    </v-menu>
</template>

<script type="text/babel">
    import moment from 'moment';
    export default {
        name: 'ReportDatePicker',
        components: {},
        data: (vm) => ({
            menu: false,
            date: '',
            dateFormatted: vm.formatDate(new Date().toISOString().substr(0, 10)),
        }),
        props: ["value", "label", "clearable", 'error', 'message', 'dense', "min", 'max', 'default_today', 'placeholder', 'solo','flat', 'prepend_icon','todo','change', 'singleLine', 'adjustLabel', 'removePaddingMargin','adjustForContactDetails','clearIcon'],
        filters: {
            formatDateOnly: function(value) {
                let date = null
                if(value){
                    date = moment(value).format('MM/DD/YYYY');
                } 
                return date;
            }
        },
        mounted(){
            if(this.value){
                this.date = moment(this.value).format('YYYY-MM-DD');
            } else if (this.default_today){
                this.date = this.currentDate;
            }
            this.$forceUpdate();
        },
        computed:{
            defaultValue () {
                if(this.solo === false) return this.solo;
                return true;
            },
            computedDateFormatted () {
                
                if(!this.date) return;
                return this.formatDate(this.date);
            },
            currentDate(){
              return new Date().toISOString().substr(0, 10);
            },
            // fieldValue: {
            //     get: function() {
            //         if(!this.value) return null;
            //         return moment(this.value, 'YYYY-MM-DD').startOf('day').format('MM/DD/YYYY')
            //     },
            //     set: function(value) {
            //         this.$emit('input',  moment(this.value).format('YYYY-MM-DD'))
            //     }
            // }
        },
        methods:{
            formatDate (date) {
                if (!date) return null;
                const [year, month, day] = date.split('-')
                return `${month}/${day}/${year}`
            },
            setValue(value){
                this.menu = false;
                // when user clear input it will remove value from date picker
                let newDate = ''
                if(value){
                    newDate = moment(value).format('YYYY-MM-DD')
                }
                this.$emit('input', newDate);
                this.$emit('change', newDate);

            },
        },
        watch: {
            value(){
                if(this.value) {
                    this.date = moment(this.value).format('YYYY-MM-DD');
                } else {
                    this.date = '';
                }
            },
            message(){
                if(this.date){
                    this.message = '';
                }
            }
        }
        // date (val) {
        //     this.dateFormatted = this.formatDate(this.date)
        // },
    }
</script>

<style scoped>
.adjust-for-contact-details {
    margin-top: -6px;
    margin-bottom: -6px;
    margin-left: -2px;
}

.adjust-for-contact-details >>> .v-input__control {
    min-height: 0 !important;
}

.adjust-for-contact-details >>> .v-input__slot {
    padding: 0 !important;
}
</style>

<style>

.hummingbird-date-text-field-wrapper.v-input.error--text .v-input__prepend-outer .v-icon, .hummingbird-date-text-field-wrapper.v-input.error--text .v-input__prepend-inner .v-icon, .hummingbird-date-text-field-wrapper.v-input.error--text .v-input__append-inner .v-icon {
    color: #FB4C4C !important;
}
.hummingbird-date-text-field-wrapper .v-input__control > .v-input__slot{
    min-height: 29px !important;
}

div.hummingbird-date-text-field-wrapper .v-input__control > .v-input__slot > .v-input__prepend-inner{
    margin-top: 7px !important;
}

</style>
