<script type="text/babel">
import { EventBus } from "../../EventBus.js";

export default {
  name: "ReportsDownloadHistoryActions",
  computed: {
    items() {
        return [
            {
                text: "Download",
                event: "downloadHistoryReport",
            },
      ];
    },
  },
  methods: {
    triggerEvent(event) {
        EventBus.$emit("reportsDownloadEvents", {
          event,
          data: this.params.data,
        });
    },
  },
};
</script>

<template>
  <div>
    <v-card class="mx-auto" max-width="500">
      <v-list>
        <v-list-item-group>
          <v-list-item
            v-for="(item, i) in items"
            @click="triggerEvent(item.event)"
            :key="i"
          >
            <v-list-item-content>
              <v-list-item-title v-text="item.text"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-card>
  </div>
</template>