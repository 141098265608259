<template>
  <div>
    <v-row style="background: #f9fafb" no-gutters class="my-3">
      <v-col :cols="5" class="schedule-search-wrapper">
        <hb-text-field
          search
          full
          v-model="search"
          placeholder="Search"
          @click:clear="clearSearch"
          width="525px"
        ></hb-text-field>
      </v-col>
      <v-col :cols="7" align="right">
      </v-col>
    </v-row>
    <div class="primary-section-content">
      <hb-data-table
        :headers="propHeaders"
        :items="scheduledReports"
        :loading="isFetching"
        loading-text="Loading... Please wait"
        :search="search"
        :custom-filter="filterSearching"
        :custom-sort="customSort"
        @click:row="viewEditReport"
        mobile-breakpoint="320"
      >
        <!-- Name -->
        <template v-slot:item.share_report_title="{ item }">
          <span
            ><div class="schedule-name-column">
              {{ item.share_report_title }}
            </div></span
          >
        </template>
        <!-- Reports -->
        <template v-slot:item.reports="{ item }">
          <hb-tooltip v-if="item.reports.length">
            <template v-slot:item>
              <span
                ><div class="text-border">
                  {{ item.reports.length }} Reports
                </div></span
              >
            </template>
            <template v-slot:body>
              <div v-for="(report, i) in item.reports" :key="i">
                <div>- {{ report.name }}</div>
              </div>
            </template>
          </hb-tooltip>
          <span class="empty-color" v-else>
            <hb-icon small color="#FB4C4C">mdi-alert</hb-icon>
            <span style="padding-top: 2px; padding-left: 2px"> 0 Reports</span>
          </span>
        </template>

        <!-- Properties -->
        <template v-slot:item.properties="{ item }">
          <hb-tooltip v-if="item.properties.length">
            <template v-slot:item>
              <span
                ><div class="text-border">
                  {{ item.properties.length }} of Properties
                </div></span
              >
            </template>
            <template v-slot:body>
              <div v-for="property in item.properties" :key="property.id">
                <div>- {{ property.number }} {{ property.Address.city }}</div>
              </div>
            </template>
          </hb-tooltip>
          <span class="empty-color" v-else>
            <hb-icon small color="#FB4C4C">mdi-alert</hb-icon>
            <span style="padding-top: 2px; padding-left: 2px">
              0 Properties</span
            >
          </span>
        </template>

        <!-- Recipient(s) -->
        <template v-slot:item.send_to="{ item }">
          <hb-tooltip
            v-if="
              item.send_to.length +
                item.emails_recipients.length +
                item.sms_recipients.length
            "
          >
            <template v-slot:item>
              <span
                ><div class="text-border">
                  {{
                    item.send_to.length +
                      item.emails_recipients.length +
                      item.sms_recipients.length
                  }}
                  Recipient
                </div>
              </span>
            </template>
            <template v-slot:body>
              <div
                v-for="co_worker in item.send_to"
                :key="co_worker.contact_id"
              >
                <div>- {{ co_worker.first + " " + co_worker.last }}</div>
              </div>
              <div
                v-for="(email_recipient, index) in item.emails_recipients"
                :key="index"
              >
                <div v-if="email_recipient.email.length">
                  - {{ email_recipient.email }}
                </div>
              </div>
              <div
                v-for="(sms_recipient, index) in item.sms_recipients"
                :key="sms_recipient.code + index"
              >
                <div v-if="sms_recipient.phone.length">
                  - {{ sms_recipient.code }} {{ sms_recipient.phone }}
                </div>
              </div>
            </template>
          </hb-tooltip>
          <span class="empty-color" v-else>
            <hb-icon small color="#FB4C4C">mdi-alert</hb-icon>
            <span style="padding-top: 2px; padding-left: 2px">
              0 Recipients</span
            >
          </span>
        </template>

        <!-- Date Created -->
        <template v-slot:item.created_date="{ item }">
          <span>{{ item.created_date | formatDate("MMM DD, YYYY") }}</span>
        </template>

        <template v-slot:item.actions="{ item }" v-if="hasPermission('edit_scheduled_reports') || hasPermission('delete_scheduled_reports')">
          <hb-menu options align-right content-class="view-scheduled-reports-options-menu">
            <v-list>
              <v-list-item v-if="hasPermission('edit_scheduled_reports')">
                <v-list-item-title @click="viewEditReport(item)">
                  Edit Schedule
                </v-list-item-title>
              </v-list-item>
              <v-list-item v-if="hasPermission('delete_scheduled_reports')">
                <v-list-item-title @click="OpenDeleteScheduleModal(item)">
                  Delete Schedule
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </hb-menu>
        </template>
      </hb-data-table>
    </div>
    <hb-modal
      v-model="confirmDeleteModal"
      v-if="confirmDeleteModal"
      size="medium"
      title="Delete Schedule"
      confirmation
      @close="closeDeleteScheduleModal()"
      show-help-link
    >
      <template v-slot:content>
        <div class="py-4 px-6">
          By deleting this scheduled report, effective immediatly all recipients
          will no longer receive any reports. This includes yourself,
          Hummingbird users, or any recipients who have been scheduled to
          receive this report.
          <br /><br />
          Are you sure you want to delete this scheduled report?
        </div>
      </template>
      <template v-slot:actions>
        <hb-btn
          color="destructive"
          @click="deleteSchduledReport()"
          :disabled="isLoading('delete_schduled_report')"
          >Delete Schedule</hb-btn
        >
      </template>
    </hb-modal>

    <!-- view schedule modal -->
    <report-share-modal
      v-if="showReportModal"
      v-model="showReportModal"
      :isEdit="true"
      :property="[]"
      :checkedReports="selectedReport"
      :openForSchedule="openForSchedule"
      :scheduleInfo="scheduleInfo"
      @close="closeReportModal"
      @fetchUpdatedData="fetchUpdatedData"
      @closeShareSchedule="closeReportModal"
      @removeViewScheduleReport="removeViewScheduleReport"
    >
    </report-share-modal>
  </div>
</template>
<script type="text/babel">
import api from "../../assets/api.js";
import { notificationMixin } from "@/mixins/notificationMixin.js";
import ReportShareModal from "./Common/ReportShareModal.vue";
import moment from "moment";
import properties from '../../assets/api/properties';

export default {
  name: "ViewScheduledReports",
  data() {
    return {
      search: "",
      searchInput: "",
      originalScheduledReports: [],
      isFetching: false,
      propHeaders: [
        {
          text: "Name",
          value: "share_report_title",
          sortable: true,
          align: "left",
          width: '200px'
        },
        {
          text: "Delivery Schedule",
          value: "frequency",
          sortable: true,
          align: "left"
        },
        // {
        //   text: "Report Period",
        //   value: "report_period",
        //   sortable: true,
        //   align: "left"
        // },
        { text: "Report(s)", value: "reports", sortable: true, align: "left" },
        {
          text: "# of Properties",
          value: "properties",
          sortable: true,
          align: "left"
        },
        {
          text: "Recipient(s)",
          value: "send_to",
          sortable: true,
          align: "left"
        },
        {
          text: "Date Created",
          value: "created_date",
          sortable: true,
          align: "left"
        },
        { text: null, value: "actions", sortable: false, align: "left" }
      ],
      scheduledReports: [],
      searchField: "",
      searchItems: [],
      confirmDeleteModal: false,
      selectedReport: null,
      showReportModal: false,
      openForSchedule: true,
      scheduleInfo: {},
      profile1Options: [
        "Today",
        "Yesterday",
        "Last 7 Days",
        "Last 30 Days",
        "This Month",
        "Last Month",
        "Year To Date",
        "Custom Date",
        "Custom Range"
      ],
      profile2Options: ["Today", "Yesterday", "Custom Date"],
      sortingSchedule:[]
    };
  },
  components: { ReportShareModal },
  mixins: [notificationMixin],
  created() {
    this.fetchData();
  },
  methods: {
    customSort(items, index, isDesc) {
      items.sort((a, b) => {
        if (index.includes("send_to")) {
          return this.checkCompare(isDesc[0], a.count, b.count);
        }
        if (index.includes("reports")) {
          return this.checkCompare(isDesc[0], a.reports.length, b.reports.length);
        }
        if (index.includes("created_date")) {
          return this.checkCompare(isDesc[0], a.created_date, b.created_date);
        }
        if (index.includes("share_report_title")) {
          return this.checkCompare(isDesc[0], a.share_report_title.toLowerCase(), b.share_report_title.toLowerCase());
        }
        if (index.includes("frequency")) {
          return this.checkCompare(isDesc[0], a.frequency.toLowerCase(), b.frequency.toLowerCase());
        }
        if (index.includes("properties")) {
          return this.checkCompare(isDesc[0], a.properties.length, b.properties.length);
        }
      });
      return items;
    },
    checkCompare(i, a, b) {
      if (i) {
        return this.checkDescending(a, b);
      } else {
        return this.checkAscending(a, b);
      }
    },
    checkDescending(a, b) {
      if (a > b) {
        return -1;
      }
      if (a < b) {
        return 1;
      }
      return 0;
    },
    checkAscending(a, b) {
      if (a < b) {
        return -1;
      }
      if (a > b) {
        return 1;
      }
      return 0;
    },
    fetchUpdatedData() {
      this.fetchData();
    },
    fetchData() {
      this.isFetching = true;
      api
        .get(this, api.REPOPRT_LIBRARY_SCHEDULED_REPORTS)
        .then(r => {
          this.originalScheduledReports = r;
          this.scheduledReports = r;
           this.sortingSchedule = r.filter((element)=>{ 
              element['count'] = eval(element.send_to.length + element.emails_recipients.length + element.sms_recipients.length) 
            return element
           })
          this.isFetching = false;
        })
        .catch(e => {
          this.showMessageNotification({
            type: "error",
            description: "Please try again.!"
          });
          this.isFetching = false;
          this.scheduledReports = [];
        });
    },
    viewEditReport(item) {
      if(!this.hasPermission('edit_scheduled_reports')) return

      let scheduleReports = [];
      item.reports.forEach(el => {
        const r = {};
        let format = el.format;
        if(el.format === 'web') {
          format = "pdf";
        }
        r["report"] = el.original_report
        r["reportDetails"] = {
          end_date: el.report_period.end_date,
          file_type: format,
          // profileSelected: el.profile == 1 ? "timeframe" : "date",
          shareDynamicFilter: el.filters,
          // shareReportTimePeriod:
          //   el.profile == 1 ? this.profile1Options : this.profile2Options,
          shareReportTimeSelected: el.report_period.label,
          start_date: el.report_period.start_date,
          unit_group_profile_id_share: el.unit_group_profile_id
        };
        if (r.reportDetails.shareReportTimeSelected !== "Custom Date" || r.reportDetails.shareReportTimeSelected !== "Custom Range")
          {
            r.reportDetails.start_date = moment().format("YYYY-MM-DD");
            r.reportDetails.end_date = moment().format("YYYY-MM-DD");
          }
        scheduleReports.push(r);
      });
      this.selectedReport = scheduleReports;
      let emailData = [{ email: "" }];
      let smsData = [{ phone: "", code: "+1" }];
      this.scheduleInfo = {
        deliverySetupReportName: item.share_report_title,
        frequency: item.frequency,
        property: item.properties,
        CoworkersArray: item.send_to,
        emailRecipientArray: !item.emails_recipients.length
          ? emailData
          : item.emails_recipients,
        smsRecipientArray: !item.sms_recipients.length
          ? smsData
          : item.sms_recipients,
        scheduleId: item.id
      };
      this.showReportModal = true;
    },
    closeReportModal() {
      this.showReportModal = false;
      this.selectedReport = null;
    },
    OpenDeleteScheduleModal(report) {
      this.confirmDeleteModal = true;
      this.selectedReport = report;
    },
    closeDeleteScheduleModal() {
      this.confirmDeleteModal = false;
      this.selectedReport = null;
    },
    deleteSchduledReport() {
      this.startLoading("delete_schduled_report");
      api
        .delete(
          this,
          api.REPOPRT_LIBRARY_SCHEDULED_REPORTS + "/" + this.selectedReport.id
        )
        .then(r => {
          this.showMessageNotification({
            type: "success",
            description:
             'Your schedule report has been successfully deleted'
          });
          this.stopLoading("delete_schduled_report");
          this.closeDeleteScheduleModal();
          this.fetchData();
        })
        .catch(e => {
          this.showMessageNotification({
            type: "error",
            description: "Please try again.!"
          });
        });
    },
    filterSearching(value, search, item) {
      let flag = false;
      
      const updatedPropertiesArray = item.properties.map(propItem=>{
          return {
                ...propItem,
                propString:propItem.number+' '+propItem.Address.city
              } 
      })
      const updatedSendToObject = item && item.send_to.map(sendTo=>{
            return {
              ...sendTo,
              sendToString:sendTo.first+' '+sendTo.last
            } 
      })
      
      if (value && Array.isArray(value) && value.length) {
        const find = value.find(el => {
          return (
            (el &&
              el.hasOwnProperty("contact_id") &&
              el.first &&
              el.first.length &&
              el.first
                .toString()
                .toUpperCase()
                .includes(search.toUpperCase())) ||
            (el &&
              el.hasOwnProperty("report_id") &&
              el.name &&
              el.name.length &&
              el.name
                .toString()
                .toUpperCase()
                .includes(search.toUpperCase())) ||
            (el &&
              el.hasOwnProperty("contact_id") &&
              el.last &&
              el.last.length &&
              el.last
                .toString()
                .toUpperCase()
                .includes(search.toUpperCase())) ||
            (el &&
              el.hasOwnProperty("company_id") &&
              el.number &&
              el.number.length &&
              el.number
                .toString()
                .toUpperCase()
                .includes(search.toUpperCase())) ||
            (el &&
              el.hasOwnProperty("company_id") &&
              el.Address.city &&
              el.Address.city.length &&
              el.Address.city
                .toString()
                .toUpperCase()
                .includes(search.toUpperCase())) ||
            // Check for full property name by combining number and city prop // ex: S1004 Irivine
            updatedPropertiesArray.find(item=>item.propString.toLowerCase().includes(search.toLowerCase()))) ||
            // Check for full name by combining first and the last name.  // ex: Lorem Ipsum
            updatedSendToObject.find(item=>item.sendToString.toLowerCase().includes(search.toLowerCase()))

        });
        if (find) {
          flag = true;
        }
      } else if (value && typeof value === "string" && value.length) {
        if (!/[a-zA-Z]/.test(value)) {
          flag = moment(value)
            .format("MMM DD, YYYY")
            .toString()
            .toLowerCase()
            .includes(search.toLowerCase())
            ? true
            : false;
        } else {
          flag =
            value
              .toString()
              .toLowerCase()
              .indexOf(search.toLowerCase()) !== -1
              ? true
              : false;
        }
      } else {
        if (item.sms_recipients && item.sms_recipients.length) {
          const found = item.sms_recipients.find(
            sms =>
              (sms.hasOwnProperty("phone") &&
                sms.phone &&
                sms.phone.length &&
                sms.phone
                  .toString()
                  .toUpperCase()
                  .includes(search.toUpperCase())) ||
              (sms &&
                sms.hasOwnProperty("code") &&
                sms.code &&
                sms.code.length &&
                sms.code
                  .toString()
                  .toUpperCase()
                  .includes(search.toUpperCase()))
          );
          if (found) {
            flag = true;
          }
        }
        if (item.emails_recipients && item.emails_recipients.length) {
          const emailFound = item.emails_recipients.find(
            sms =>
              sms.hasOwnProperty("email") &&
              sms.email &&
              sms.email.length &&
              sms.email
                .toString()
                .toUpperCase()
                .includes(search.toUpperCase())
          );
          if (emailFound) {
            flag = true;
          }
        }
      }

      if (flag) {
        return true;
      }
    },
    setSearchParams() {
      this.search = this.searchInput;
    },
    clearSearch() {
      this.scheduledReports = [];
      this.scheduledReports = this.originalScheduledReports;
    },
    removeViewScheduleReport(id) {
      this.selectedReport = this.selectedReport.filter(
        el => el.report.id !== id
      );
    }
  }
};
</script>
<style lang="scss" scoped>
.empty-color {
  color: #fb4c4c;
}
.text-border {
  display: inherit;
  border-width: 1px !important;
  border-color: #101318 !important;
  border-bottom-style: dashed !important;
}
.schedule-name-column {
  word-break: break-word;
  max-width: 169px;
}
.view-scheduled-reports-options-menu .v-list-item:hover {
  background-color: #f7f7f7;
  cursor: pointer;
}
</style>
<style>
.schedule-search-wrapper
  > .v-text-field--enclosed.v-input--dense:not(.v-text-field--solo).v-text-field--outlined
  .v-input__prepend-inner {
  margin-top: 8px !important;
}
.schedule-search-wrapper > .v-input__icon--clear {
  margin-top: 0px !important;
}
</style>
