<template>
  <div class="report-share-modal-wrapper">
    <hb-modal
      size="large"
      :title="!showScheduleModal ? 'Share Report - One Time Delivery' : ScheduleHeader"
      v-model="dialog"
      @close="closeViewEditModal"
      :footerCancelOption="true"
      show-help-link
      title-icon="mdi-user-actions-custom-1"
    >
      <template v-slot:subheader>
        <span v-if="!showScheduleModal"
          >Configure the settings below in order to share the report.</span
        >
        <span v-else
          >Configure the settings below in order to schedule the report.</span
        > </template
      ><template v-slot:icon>
        <hb-icon tooltip="Share" color="#101318" class="pr-2"
          >mdi-user-actions-custom-1
        </hb-icon>
      </template>
      <template v-slot:content>
        <div v-for="(field, i) in shareReportField" :key="i">
          <hb-form
            :full="true"
            :label="field.name"
            :required="field.required"
            :tooltip="!!field.tooltip"
            :tooltip-body="field.tooltip"
            :editable="edit"
            :description="field.description"
            :auto-layout="false"
            :last="i === shareReportField.length - 1"
          >
            <div
              v-if="field.name == 'Setup Repeat Delivery'"
              class="repeat-form-container"
            >
              <div class="schedule-field-wrapper">
                <HbTextField
                  v-model="deliverySetupReportName"
                  placeholder="Enter name"
                  data-vv-as="Setup Repeat Delivery"
                  data-vv-name="delivery-setup"
                  name="deliver-setup"
                  id="delivery-setup"
                  :error="errorNameField"
                  @focus="handleFieldFocus('deliver-setup')"
                />
              </div>

              <div class="font-regular hb-text-night-light mt-6 mb-3">
                Select how often this report will be sent to the recipients.
              </div>
              <div class="schedule-field-wrapper">
                <HbSelect
                  :items="scheduledFrequencies"
                  v-model="scheduledFrequency"
                  placeholder="Select Frequency"
                  item-value="id"
                  item-text="name"
                  :error="errorFrequencyField"
                  id="Schedule Frequency"
                  name="Schedule Frequency"
                  @focus="handleFieldFocus('frequency')"
                  @change="handleFieldFocus('frequency')"
                />
              </div>
            </div>
            <div v-if="field.name == 'Reports'" class="mb-n3 reports-container">
              <v-row no-gutters>
                <v-col
                  cols="8"
                  class="share-report-modal"
                  v-if="getCheckedReports && getCheckedReports.length"
                >
                  <report-accordian
                    :name="report.id"
                    :id="report.id"
                    v-for="(report, index) in getCheckedReports"
                    :key="index"
                    :report="report.report"
                    :isShare="true"
                    :report-details="report.reportDetails"
                    :collectionName="
                      report.original_collection_name ||
                      (report.original_report
                        ? report.original_report.collection_name
                        : collectionName)
                    "
                    :reportCount="getCheckedReports.length"
                    :scheduleFlag="ifSchedule"
                    :ScheduleEdit="ScheduleEdit"
                    :reportInModal="reportInModal"
                    @removeLastReport="removeLastReport"
                    :emptySpaceGroupFlag="emptySpaceGroupFlag"
                    :isScheduleEdit="isEdit"
                    @removeViewReport="removeViewReport"
                    :isFieldError="fileArr"
                    @handleSelectFocus="handleSelectFocus"
                    @removeReport="removeReportShare"
                  ></report-accordian>
                </v-col>
                <v-col cols="4"></v-col>
              </v-row>
            </div>
            <div class="coworker-autocomplete-wrapper co-worker-properties">
              <v-combobox
                v-if="field.name == 'Properties'"
                v-model="propertyArray"
                item-value="id"
                :items="properties"
                return-object
                :placeholder="
                  propertyArray && !propertyArray.length ? 'Choose Property' : ''
                "
                multiple
                :clearable="properties.length === 1 ? false : true"
                clear-icon="mdi-close-circle"
                name="property_"
                id="property_"
                :error="errorPropertyfield"
                data-vv-as="Properties"
                class="pill-chip-wraper design-system-adherence-370-max-width-input"
                :class="[errorPropertyfield ? 'propertyError' : '']"
                @focus="handleFieldFocus('properties')"
                :style="propertyArray && !propertyArray.length ? 'position:relative;top:-8px;' : ''"
                :menu-props="{
                    contentClass: 'design-system-adherence-list-item-height'
                }"
              >
                <template v-slot:selection="{ item, parent }">
                  <hb-chip @click="parent.selectItem(item)" editable>
                    <span v-if="item.number" class="mr-1">{{ item.number }} -</span
                    >{{ item.Address.city }}
                  </hb-chip>
                </template>
                <template v-slot:prepend-item>
                  <v-list-item
                      @click="toggleProperty"
                      v-if="properties.length"
                      :class="[
                        icon == 'check_box' ? 'select-all-background' : ''
                      ]"
                    >
                      <v-list-item-action style="margin: 0 8px 0 0 !important;">
                        <v-hover
                          v-slot="{ hover }"
                        >
                          <HbIcon :color="hover && icon !== 'check_box' ? '#637381' : icon == 'check_box' || propertyArray.length > 0 ? '#00848E' : '#DFE3E8'" :mdi-code="icon" />
                        </v-hover>
                      </v-list-item-action>
                      <v-list-item-content :class="icon == 'check_box' ? 'primary--text' : ''">
                        <v-list-item-title>Select All</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                </template>
                <template v-slot:item="{item, attrs}">
                    <v-list-item-action style="margin: 0 8px 0 0 !important;">
                      <hb-icon v-if="attrs.inputValue" color="#00848E">check_box</hb-icon>
                      <v-hover
                        v-slot="{ hover }"
                        v-else
                      >
                        <hb-icon :color="hover ? '#637381' : '#DFE3E8'">check_box_outline_blank</hb-icon>
                      </v-hover>
                    </v-list-item-action>
                    <v-list-item-content> 
                      <v-list-item-title> 
                        <span v-if="item.number" class="mr-1">{{ item.number }} - </span>
                        {{ item.Address.city }} - {{ item.Address.address }}
                      </v-list-item-title> 
                    </v-list-item-content> 
                  </template>
              </v-combobox>
            </div>
            <div v-if="field.name == 'Recipients'">
              <div class="coworker-autocomplete-wrapper coworker-div-wrapper">
                <v-combobox
                  v-model="CoworkersArray"
                  :items="admins"
                  item-text="contact_id"
                  return-object
                  clearable
                  clear-icon="mdi-close-circle"
                  multiple
                  data-vv-as="Co-workers"
                  :name="'co_workers'"
                  :id="'co_workers'"
                  :error="!!errors.collect('co_workers').length"
                  :placeholder="!CoworkersArray.length ? 'Search for Co-workers' : ''"
                  :label="CoworkersArray.length ? 'Co-workers' : ''"
                  :class="[CoworkersArray.length ? 'co-workers-top ' : '']"
                  class="pill-chip-wraper design-system-adherence-370-max-width-input"
                  :filter="filterCoworker"
                  :hide-no-data="!searchCoworker"
                  :search-input.sync="searchCoworker"
                  @change="changeCoworker"
                  :menu-props="{
                    contentClass: 'design-system-adherence-list-item-height'
                  }"
                >
                  <template v-slot:selection="{ item, parent }">
                    <hb-chip @click="parent.selectItem(item)" editable>
                      {{ item.first }} {{ item.last }}
                    </hb-chip>
                  </template>
                  <template v-slot:prepend-item>
                    <v-list-item
                      @click="toggleCoworker"
                      v-if="admins.length"
                      :class="[
                        coWorkericon == 'check_box' ? 'select-all-background' : '',
                      ]"
                    >
                      <v-list-item-action style="margin: 0 8px 0 0 !important;">
                        <v-hover
                          v-slot="{ hover }"
                        >
                          <HbIcon :color="hover && coWorkericon !== 'check_box' ? '#637381' : coWorkericon == 'check_box' || CoworkersArray.length > 0 ? '#00848E' : '#DFE3E8'" :mdi-code="coWorkericon" />
                        </v-hover>
                      </v-list-item-action>
                      <v-list-item-content :class="coWorkericon == 'check_box' ? 'primary--text' : ''">
                        <v-list-item-title>Select All</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                  <template v-slot:item="{item, attrs}">
                    <v-list-item-action style="margin: 0 8px 0 0 !important;">
                      <hb-icon v-if="attrs.inputValue" color="#00848E">check_box</hb-icon>
                      <v-hover
                        v-slot="{ hover }"
                        v-else
                      >
                        <hb-icon :color="hover ? '#637381' : '#DFE3E8'">check_box_outline_blank</hb-icon>
                      </v-hover>
                    </v-list-item-action>
                    <v-list-item-content> 
                      <v-list-item-title> 
                        {{ item.first }} {{ item.last }}
                      </v-list-item-title> 
                    </v-list-item-content> 
                  </template>
                </v-combobox>
              </div>
              <div class="email-recipients email-modal-recipients">
                <span>Email Recipients</span>
              </div>
              <v-row
                class="my-0 py-0"
                v-for="(email, i) in emailRecipientArray"
                :key="'email_' + i"
                style="margin-bottom: 0px !important"
              >
                <v-col class="my-0 py-0 email-text-field-wrapper">
                  <v-text-field
                    v-model="emailRecipientArray[i].email"
                    placeholder="Enter Email"
                    autocomplete="off"
                    @blur="addEmail()"
                    data-vv-as="email recipients"
                    :name="'email_' + i"
                    :id="'email_' + i"
                    :error="
                      !!errors.collect('email_' + i).length ||
                      !emailRecipientArray[i].isValid
                    "
                    :class="[
                      i == 0 ? 'email-recipients-first' : 'email-recipients-others',
                    ]"
                  >
                  </v-text-field>
                </v-col>
                <v-col class="my-0 py-0">
                  <hb-icon v-if="i !== 0" @click="removeEmail(i)" pointer>mdi-close</hb-icon>
                </v-col>
              </v-row>
              <div class="sms-recipient sms-modal-recipient">
                <span>SMS Recipients</span>
              </div>
              <div
                v-for="(phone, i) in smsRecipientArray"
                :key="'phone_' + i"
                class="d-flex phone-div"
              >
                <div style="width: 56px !important; margin-right: 10px" class="phone-div">
                  <v-select
                    style="width: 56px !important"
                    :items="countryCodesList"
                    :hide-details="!errors.collect('general.phone_code').length"
                    v-model="smsRecipientArray[i].code"
                    label="Code"
                    data-vv-as="phone code"
                    single-line
                    :id="'phone_code' + i"
                    :name="'phone_code' + i"
                    :error="!!errors.collect('phone_code' + i).length"
                  >
                    <template v-slot:selection="data"> +{{ data.item }} </template>
                    <template v-slot:item="data"> +{{ data.item }} </template>
                  </v-select>
                </div>
                <div
                  :class="[i == 0 ? 'phone-number-first' : 'phone-number-others']"
                  class="phone-div"
                >
                  <v-text-field
                    v-model="smsRecipientArray[i].phone"
                    @blur="addPhone(i)"
                    autocomplete="off"
                    v-mask="
                      smsRecipientArray[i].code === '1'
                        ? '(###) ###-####'
                        : '##############'
                    "
                    label="Enter Phone"
                    single-line
                    type="tel"
                    :id="'phone_' + i"
                    :name="'phone_' + i"
                    data-vv-as="phone number"
                    :error="
                      !!errors.collect('phone_' + i).length ||
                      !smsRecipientArray[i].isValid
                    "
                    :class="[i == 0 ? 'phone-number-first' : 'phone-number-others']"
                  ></v-text-field>
                </div>
                <div style="margin-left: 20px">
                  <hb-icon v-if="i !== 0" @click="removePhone(i)" pointer> mdi-close </hb-icon>
                </div>
              </div>
            </div>
          </hb-form>
        </div>
      </template>
      <template v-slot:right-actions>
        <hb-btn
          :loading="loading"
          v-if="!showScheduleModal"
          @click="shareScheduleValidation"
          >Share</hb-btn
        >
        <hb-btn :loading="loading" v-else @click="shareScheduleValidation">{{
          SaveButton
        }}</hb-btn>
      </template>
    </hb-modal>
  </div>
</template>

<script>
import moment from 'moment';
import {
  mapGetters,
  mapMutations,
} from 'vuex';

import PHONE from '@/constants/phone.js';
import { notificationMixin } from '@/mixins/notificationMixin.js';
import { validationMixin } from '@/mixins/validationMixin.js';

import api from '../../../assets/api.js';
import { EventBus } from '../../../EventBus.js';

export default {
  name: "ShareReport",
  props: {
    value: {
      type: Boolean,
    },
    reports: { type: Array },
    property: { type: Array },
    reportDetails: { type: Object },
    isEdit: { type: Boolean },
    report: { type: Object },
    checkedReports: { type: Array },
    openForSchedule: { type: Boolean },
    scheduleInfo: { type: Object },
    collectionName: { type: String },
    MultipleType: { type: String },
    reportInModal: { type: Boolean },
  },
  data() {
    return {
      shareReportField: [
        {
          name: "Setup Repeat Delivery",
          required: true,
          tooltip:
            "Repeat Deliveries allow you to share reports to people inside or outside your company at selected frequencies, such as daily or weekly. ", // tooltip needs update
          description: "Create a name for your Repeat Delivery Schedule",
        },
        { name: "Reports", required: true },
        { name: "Properties", required: true },
        {
          name: "Recipients",
          required: true,
          description:
            "Search for the co-workers that you would like to both email and text these reports to. In addition you can also add email addresses and phone numbers for people outside your company.",
        },
      ],
      today: moment().format("YYYY-MM-DD"),
      yesterday: moment().subtract(1, "day").format("YYYY-MM-DD"),
      propertyArray: [],
      admins: [],
      CoworkersArray: [],
      emailRecipientArray: [{ email: "", isValid: true }],
      smsRecipientArray: [{ phone: "", code: this.company_phone_code, isValid: true }],
      countryCodesList: PHONE.COUNTRY_CODES,
      edit: false,
      deliverySetupReportName: "",
      scheduledFrequencies: [
        "End of Day",
        "End of Week",
        "End of Month",
        "End of Quarter",
        "End of Year",
      ],
      scheduledFrequency: "",
      ifSchedule: false,
      SaveButton: "Schedule",
      ScheduleHeader: "Share Report - Repeat Delivery",
      removeLastReportFlag: false,
      ScheduleEdit: false,
      emptySpaceGroupFlag: false,
      searchCoworker: null,
      loading: false,
      errorPropertyfield: false,
      errorEmailField: false,
      errorNameField: false,
      errorFrequencyField: false,
      checkedReportsShare: [],
      errorList: [],
      fileArr: [],
      nonAccessibleProperties: [],
      osrErrorDescription: 'Following properties have a missing or multiple default space groups.',
      osrErrorNote: `Note: You can update the default space group from Settings > Space Groups. There should be only one default space group per property.`
    };
  },
  components: {
    "report-accordian": () => import("../ReportAccordian.vue"),
  },
  mounted(){
    this.smsRecipientArray = [{ phone: "", code: this.company_phone_code, isValid: true }]
  },
  mixins: [notificationMixin, validationMixin],
  computed: {
    ...mapGetters({
      company_phone_code: 'authenticationStore/getCompanyPhoneCode',
      properties: "propertiesStore/filtered",
      getScheduleType: "reportLibraryStore/getScheduleType",
    }),
    dialog: {
      get() {
        return this.value;
      },
      set() {},
    },
    selectAllProperty() {
      return this.propertyArray
        ? this.properties.every((elem) => this.propertyArray.includes(elem))
        : false;
    },
    selectAllCoWorker() {
      return this.admins.every((elem) => this.CoworkersArray.includes(elem));
    },
    icon() {
      if (this.propertyArray && this.propertyArray.length === 0)
        return "mdi-checkbox-blank-outline";
      if (this.selectAllProperty) return "check_box";
      if (!this.selectAllProperty) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
    coWorkericon() {
      if (this.CoworkersArray.length === 0) return "mdi-checkbox-blank-outline";
      if (this.selectAllCoWorker) return "check_box";
      if (!this.selectAllCoWorker) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
    showScheduleModal() {
      this.ifSchedule = this.openForSchedule;
      this.ifSchedule =
        this.MultipleType && this.MultipleType == "repeated" ? true : this.ifSchedule;
      return this.ifSchedule;
    },
    getCheckedReports() {
      return this.removeLastReportFlag ? [] : this.checkedReportsShare;
    },
  },
  created() {
    this.checkedReportsShare = JSON.parse(JSON.stringify(this.checkedReports));
    this.getAdmins();
    if (!this.showScheduleModal) {
      this.shareReportField.shift();
    }
    if (this.getCheckedReports && this.getCheckedReports.length == 1) {
      // if (this.getCheckedReports[0].report.report_category !== "static") {
      //dynamic single
      this.propertyArray = this.property && this.property.length ? this.property : [];
      // } else {
      //static single
      // }
    } else {
      //multiple
      this.propertyArray = [];
    }
    if (this.isEdit) {
      this.ScheduleEdit = this.isEdit;
      this.SaveButton = "Save";
      this.ScheduleHeader = "View/Edit Scheduled Reports";
      this.deliverySetupReportName = this.scheduleInfo.deliverySetupReportName;
      this.scheduledFrequency = this.scheduleInfo.frequency;
      this.propertyArray = this.filterPropertyArray();
      this.CoworkersArray = this.scheduleInfo.CoworkersArray;
      this.emailRecipientArray = this.scheduleInfo.emailRecipientArray.map((el) => {
        el.isValid = true;
        return el;
      });
      this.scheduleInfo.smsRecipientArray = this.scheduleInfo.smsRecipientArray.map(
        (el) => {
          el["isValid"] = true;
          el.code = el.code.replace(/[^0-9]/g, "");
          return el;
        }
      );
      this.smsRecipientArray = this.scheduleInfo.smsRecipientArray;
    }
    if (this.propertyArray && !this.propertyArray.length) {
      this.emptySpaceGroupFlag = true;
    } else {
      this.emptySpaceGroupFlag = false;
    }
  },
  methods: {
    ...mapMutations({
      resetScheduleType: "reportLibraryStore/resetScheduleType",
      setNotificationId: "reportLibraryStore/setNotificationId",
    }),
    filterPropertyArray() {
      let properties_array = this.scheduleInfo.property || [];
      let newPropertiesArrayList = [];
      let nonAccessiblePropertiesList = [];
      properties_array.forEach(p => {
          let index = this.properties.findIndex(x => x.id === p.id)
          if (index !== -1) {
              newPropertiesArrayList.push(p);
          } else {
            nonAccessiblePropertiesList.push(p);
          }
      });
      this.nonAccessibleProperties = nonAccessiblePropertiesList;
      return newPropertiesArrayList;
    },
    changeCoworker() {
      if (this.CoworkersArray.length) {
        this.CoworkersArray = this.CoworkersArray.filter((e) => typeof e !== "string");
      }
    },
    filterCoworker(item, queryText, itemText) {
      let firstName = item.first ? item.first.toLowerCase() : "";
      let lastName = item.last ? item.last.toLowerCase() : "";
      const name = firstName + " " + lastName;
      const searchText = queryText.toLowerCase();
      return name.indexOf(searchText) > -1;
    },
    async getAdmins() {
      await api.get(this, api.ACTIVE_ADMIN).then((r) => {
        this.admins = r.admins;
      });
    },
    handleFieldFocus(fieldValue) {
      switch (fieldValue) {
        case "properties":
          this.errorPropertyfield = false;
          break;
        case "frequency":
          this.errorFrequencyField = false;
          break;
        case "deliver-setup":
          this.errorNameField = false;
          break;
      }
    },
    addEmail() {
      this.emailRecipientArray.map((el, i) => {
        if (!this.isValidEmail(el.email) && el.email) {
          el.isValid = false;
        } else {
          el.isValid = true;
        }
      });
      if (this.emailRecipientArray.every((item) => item.isValid == false)) {
        this.errorList = [
          ...this.errorList.filter(
            (item) => item != "The Email recipients field must be a valid email"
          ),
        ];
      }
      let message = {
        list: this.errorList,
        persistent: true,
      };

      if (message.list.length) {
        this.showMessageNotification(message);
      }
      if (this.emailRecipientArray[this.emailRecipientArray.length - 1].email !== "") {
        this.emailRecipientArray.push({ email: "", isValid: true });
      }
    },
    removeEmail(index) {
      this.emailRecipientArray = this.emailRecipientArray.filter((el, i) => i != index);
    },
    addPhone(i) {
      this.smsRecipientArray.forEach((el) => {
        el.phone.length
          ? !this.isValidPhoneNumber(el.code + el.phone.replace(/[^0-9]/g, ""))
            ? (el.isValid = false)
            : ("", (el.isValid = true))
          : ("", (el.isValid = true));
      });
      if (this.smsRecipientArray.every((item) => item.isValid == false)) {
        this.errorList = [
          ...this.errorList.filter(
            (item) => item != "The SMS recipients field must be a valid Phone number"
          ),
        ];
      }
      let message = {
        type: "error",
        list: this.errorList,
        persistent: true,
      };

      if (message.list.length) {
        this.showMessageNotification(message);
      }
      if (this.smsRecipientArray[this.smsRecipientArray.length - 1].phone !== "") {
        this.smsRecipientArray.push({ phone: "", code: this.company_phone_code, isValid: true });
      }
    },
    removePhone(index) {
      this.smsRecipientArray = this.smsRecipientArray.filter((el, i) => i != index);
    },
    phoneMask(code) {
      return "(###) ###-####";
    },
    handleSelectFocus(data) {
      this.$nextTick(() => {
        this.fileArr = this.fileArr.filter(function (item) {
          return item !== data;
        });
      });
    },
    shareScheduleValidation() {
      let emailsList = [],
        smsList = [],
        inValidPhones = [],
        invalidEmails = [];

      this.loading = true;
      this.emailRecipientArray.forEach((el) =>
        el.email.length ? emailsList.push(el) : ""
      );
      let plus = "+";
      this.smsRecipientArray.forEach((el) =>
        el.code.length && el.phone.length
          ? smsList.push(plus.concat(el.code.concat(el.phone.replace(/[^0-9]/g, ""))))
          : ""
      );

      // isValidPhoneNumber() is coming from mixin
      this.smsRecipientArray.forEach((ph) => {
        ph.phone.length
          ? !this.isValidPhoneNumber(ph.code + ph.phone.replace(/[^0-9]/g, ""))
            ? (inValidPhones.push(ph), (ph.isValid = false))
            : ("", (ph.isValid = true))
          : ("", (ph.isValid = true));
      });

      // isValidEmail() is coming from mixin
      emailsList.forEach((el, i) => {
        if (!this.isValidEmail(el.email)) {
          invalidEmails.push(el.email);
          this.emailRecipientArray = [
            ...this.emailRecipientArray.slice(0, i),
            { ...el, isValid: false },
            ...this.emailRecipientArray.slice(i + 1),
          ];
        } else {
          this.emailRecipientArray = [
            ...this.emailRecipientArray.slice(0, i),
            { ...el, isValid: true },
            ...this.emailRecipientArray.slice(i + 1),
          ];
        }
      });
      this.fileArr = [];
      this.checkedReportsShare.map((item) => {
        const { reportDetails } = item;
        if (!reportDetails.file_type) {
          this.fileArr.push(item.report.name);
        }
      });
      this.errorList = [];
      this.validate(this).then((status) => {
        if (!status) {
          if (!this.deliverySetupReportName.length && this.showScheduleModal) {
            this.errorList.push("The Setup Repeat Delivery field is required");
            this.errorNameField = true;
          }
          this.deliverySetupReportName.length &&
          this.showScheduleModal &&
          this.deliverySetupReportName.length > 256
            ? (this.errorList.push(
                "The Setup Repeat Delivery field may not be greater than 256 characters"
              ),
              (this.errorNameField = true))
            : "";
          invalidEmails.length
            ? (this.errorList.push("The Email recipients field must be a valid email"),
              (this.errorEmailField = true))
            : "";

          if (!this.scheduledFrequency.length && this.showScheduleModal) {
            this.errorList.push("The Schedule Frequency field is required");
            this.errorFrequencyField = true;
          }
          if (!this.propertyArray.length && !this.isEdit) {
            this.errorList.push("The Property field is required");
            this.errorPropertyfield = true;
          }
          if (
            !this.CoworkersArray.length &&
            !emailsList.length &&
            !smsList.length &&
            !this.isEdit
          ) {
            this.errorList.push("The Recipient field is required");
          }

          inValidPhones.length
            ? this.errorList.push("The SMS recipients field must be a valid Phone number")
            : "";

          this.fileArr.length
            ? this.errorList.push("The File Type field is required")
            : "";

          let message = {
            list: this.errorList,
            persistent: true,
          };

          if (message.list.length) {
            this.showMessageNotification(message);
          }

          this.loading = false;
        } else if (
          (!this.CoworkersArray.length &&
            !emailsList.length &&
            !smsList.length &&
            !this.isEdit) ||
          inValidPhones.length ||
          (!this.propertyArray.length && !this.isEdit) ||
          (!this.deliverySetupReportName.length && this.showScheduleModal) ||
          (!this.scheduledFrequency.length && this.showScheduleModal) ||
          (this.deliverySetupReportName.length &&
            this.showScheduleModal &&
            this.deliverySetupReportName.length > 256) ||
          invalidEmails.length ||
          this.fileArr.length
        ) {
          !this.deliverySetupReportName.length && this.showScheduleModal
            ? (this.errorList.push("The Setup Repeat Delivery field is required"),
              (this.errorNameField = true))
            : "";
          this.deliverySetupReportName.length &&
          this.showScheduleModal &&
          this.deliverySetupReportName.length > 256
            ? (this.errorList.push(
                "The Setup Repeat Delivery field may not be greater than 256 characters"
              ),
              (this.errorNameField = true))
            : "";
          invalidEmails.length
            ? (this.errorList.push("The Email recipients field must be a valid email"),
              (this.errorEmailField = true))
            : "";
          !this.scheduledFrequency.length && this.showScheduleModal
            ? (this.errorList.push("The Schedule Frequency field is required"),
              (this.errorFrequencyField = true))
            : "";
          !this.CoworkersArray.length &&
          !emailsList.length &&
          !smsList.length &&
          !this.isEdit
            ? this.errorList.push("The Recipient field is required")
            : "";
          !this.propertyArray.length && !this.isEdit
            ? (this.errorList.push("The Property field is required"),
              (this.errorPropertyfield = true))
            : "";

          inValidPhones.length
            ? this.errorList.push("The SMS recipients field must be a valid Phone number")
            : "";

          this.fileArr.length
            ? this.errorList.push("The File Type field is required")
            : "";

          let message = {
            list: this.errorList,
            persistent: true,
          };
          if (message.list.length) {
            this.showMessageNotification(message);
          }

          this.loading = false;
        } else {
          if (!this.showScheduleModal) {
            let postEmail = [];
            if (emailsList.length) {
              emailsList.forEach((el) => postEmail.push(el.email));
            }
            this.shareReport(postEmail, smsList);
          } else {
            this.setScheduleReport();
          }
        }
      });
    },
    shareReport(emailsList, smsList) {
      let reportStartDate = "";
      let reportEndDate = "";
      this.nonAccessibleProperties.map(p => {
        this.propertyArray.push(p);
      });
      let shareReportObj = {
        properties: this.propertyArray.map((el) => el.id),
        send_to: this.CoworkersArray,
        emails_recipients: emailsList,
        sms_recipients: smsList,
      };
      shareReportObj.reports = this.getCheckedReports.map((report) => {
        let rObject = {};
        let format = report.reportDetails.file_type || "pdf";
        let timeZone = moment.tz.guess();
        let reportPayloadObject = {
          format,
          timeZone,
          name: report.report.name,
          type: report.report.template
            ? report.report.template
            : report.report.template_type,
          label: report.reportDetails.shareReportTimeSelected,
          multiple_properties: report.report.multiple_properties,
          report_id: report.report.id,
        };
        if (report.reportDetails.shareReportTimeSelected) {
          reportPayloadObject.selected_label = this.getSelectedLabel(
            report.reportDetails
          );
        }
        if (report.reportDetails.profileSelected === "date") {
          reportStartDate =
            report.reportDetails.shareReportTimeSelected === "Today"
              ? this.today
              : report.reportDetails.shareReportTimeSelected === "Yesterday"
              ? this.yesterday
              : report.reportDetails.start_date;
        } else {
          if (report.reportDetails.shareReportTimeSelected === "Custom Date") {
            reportStartDate = report.reportDetails.start_date;
            reportEndDate = report.reportDetails.start_date;
            reportPayloadObject.label = "Custom Range";
          } else if (report.reportDetails.shareReportTimeSelected === "Custom Range") {
            reportStartDate = report.reportDetails.start_date;
            reportEndDate = report.reportDetails.end_date;
          }
        }
        // getPayloadForStaticReport
        if (report.report.report_category === "static") {
          reportPayloadObject.unit_group_profile_id =
            report.reportDetails.unit_group_profile_id_share;
          reportPayloadObject.date = reportStartDate;
          // TODO: profile2 end_date/label not required
          reportPayloadObject.end_date = reportEndDate;
        } else {
          //dynamic
          let shareFilters = null;
          if (report.report.filters) {
            shareFilters = JSON.parse(report.report.filters);
          } else {
            shareFilters = report.reportDetails.shareDynamicFilter;
          }
          if (report.reportDetails.profileSelected === "date") {
            shareFilters.search.report_date = reportStartDate;
          } else if (report.reportDetails.profileSelected === "timeframe") {
            if (!shareFilters.search.report_period) {
              shareFilters.search.report_period = {};
            }
            shareFilters.search.report_period.label = reportPayloadObject.label;
            shareFilters.search.report_period.start_date = reportStartDate;
            shareFilters.search.report_period.end_date = reportEndDate;
          }
          // Auction List does not require time period
          if (
            report.report.name == "Auction List" &&
            report.report.original_collection_name == "Delinquency Reports"
          ) {
            reportPayloadObject.selected_label.label = "";
            shareFilters.search.report_period.label = "";
            reportPayloadObject.label = "";
          }
          reportPayloadObject.filters = shareFilters;
          if (reportPayloadObject.format === "pdf") {
            reportPayloadObject.report_type = reportPayloadObject.type;
            reportPayloadObject.type = "generic";
            reportPayloadObject.format = "pdf";
            reportPayloadObject.filters = JSON.stringify(shareFilters);
          }
        }
        return reportPayloadObject;
      });
      this.shareReportsApiCall(shareReportObj);
    },
    shareReportsApiCall(payload) {
      api
        .post(this, api.REPORTS + "share-reports", { payload })
        .then((r) => {
          if (r.hasOwnProperty("invalid_phones")) {
            this.loading = false;
            this.$emit("closeShareSchedule");
            EventBus.$emit("cancelMultipleCheckbox");
            EventBus.$emit("updateCheckedReport", []);
            setTimeout(() => {
              const contNumber = r.invalid_phones.length > 1 ? "numbers" : "number";
              const text = r.invalid_phones.length > 1 ? "are" : "is";
              const reportCount = payload.reports.length > 1 ? "reports" : "report";
              this.showMessageNotification({
                description: `Below phone ${contNumber} ${text} invalid, please update the phone ${contNumber} to share the ${reportCount}`,
                list: r.invalid_phones,
                timeout: 15000,
              });
            }, 0);
          } else {
            this.setNotificationId(r.id);
            EventBus.$emit("cancelMultipleCheckbox");
            EventBus.$emit("updateCheckedReport", []);
            this.$emit("closeShareSchedule");
            const name = payload.reports.length > 1 ? "Reports" : "Report";
            setTimeout(() => {
              this.showMessageNotification({
                type: "success",
                description: `The Share ${name} process is in progress.`,
              });
            }, 0);
            this.loading = false;
          }
        })
        .catch((e) => {
          setTimeout(() => {
            this.parseAndShowError(e, this.osrErrorDescription, this.osrErrorNote);
          }, 0);
          this.$emit("closeShareSchedule");
          this.loading = false;
        });
    },
    getSelectedLabel(reportDetails) {
      let start_date = "";
      let end_date = "";
      let label = reportDetails.shareReportTimeSelected;

      if (
        ["Custom Date", "Custom Range"].includes(reportDetails.shareReportTimeSelected)
      ) {
        start_date = reportDetails.start_date;
        label = "Custom Range";
      }
      return {
        label,
        start_date,
        end_date,
      };
    },
    toggleProperty() {
      if (this.selectAllProperty) {
        this.propertyArray = [];
      } else {
        this.propertyArray = this.properties;
      }
    },
    toggleCoworker() {
      if (this.selectAllCoWorker) {
        this.CoworkersArray = [];
      } else {
        this.CoworkersArray = this.admins;
      }
    },
    setScheduleReport() {
      this.emailRecipientArray = this.emailRecipientArray.filter((el) => el.email.length);
      this.smsRecipientArray = this.smsRecipientArray.filter((el) => el.phone.length);
      let postEmail = [];
      let postSms = [];
      if (this.emailRecipientArray.length) {
        this.emailRecipientArray.forEach((el) => {
          const obj = {
            email: el.email,
          };
          postEmail.push(obj);
        });
      }
      let plus = "+";
      if (this.smsRecipientArray.length) {
        this.smsRecipientArray.forEach((el) => {
          const obj = {
            code: plus.concat(el.code),
            phone: el.phone.replace(/[^0-9]/g, ""),
          };
          postSms.push(obj);
        });
      }
      this.nonAccessibleProperties.map(p => {
        this.propertyArray.push(p);
      });
      let shareReportObj = {
        share_report_title: this.deliverySetupReportName,
        frequency: this.scheduledFrequency,
        properties:
          this.propertyArray && this.propertyArray.length
            ? this.propertyArray.map((el) => {
                if (el.id) {
                  return el.id;
                }
              })
            : [],
        timeZone: moment.tz.guess(),
        send_to: this.CoworkersArray,
        emails_recipients: postEmail,
        sms_recipients: postSms,
      };
      // removing "undefined" or "null" entries from props array.
      shareReportObj.properties = shareReportObj.properties.length && shareReportObj.properties.filter(el => el != undefined || el != null);
      shareReportObj.reports = this.getCheckedReports.map(report => {
        let format = report.reportDetails.file_type || "pdf";
        let reportPayloadObject = {
          format,
          name: report.report.name,
          type: report.report.template
            ? report.report.template
            : report.report.template_type,
          report_period: {
            label: report.reportDetails.shareReportTimeSelected,
            start_date: report.reportDetails.start_date,
            end_date: report.reportDetails.end_date,
          },
          profile: report.reportDetails.profileSelected === "timeframe" ? 1 : 2,
          static_dynamic: report.report.report_category,
        };
        if (report.reportDetails.profileSelected === "date") {
          reportPayloadObject.report_period.start_date =
            report.reportDetails.shareReportTimeSelected === "Today"
              ? this.today
              : report.reportDetails.shareReportTimeSelected === "Yesterday"
              ? this.yesterday
              : report.reportDetails.start_date;
        } else {
          if (report.reportDetails.shareReportTimeSelected === "Custom Date") {
            reportPayloadObject.report_period.start_date =
              report.reportDetails.start_date;
            reportPayloadObject.report_period.end_date = report.reportDetails.start_date;
          } else if (report.reportDetails.shareReportTimeSelected === "Custom Range") {
            reportPayloadObject.report_period.start_date =
              report.reportDetails.start_date;
            reportPayloadObject.report_period.end_date = report.reportDetails.end_date;
          }
        }
        if (report.report.report_category === "static") {
          reportPayloadObject.unit_group_profile_id =
            report.reportDetails.unit_group_profile_id_share;
        } else {
          //dynamic
          let shareFilters = null;
          if (report.report.filters) {
            shareFilters = JSON.parse(report.report.filters);
          } else {
            shareFilters = typeof report.reportDetails.shareDynamicFilter === 'string' ? JSON.parse(report.reportDetails.shareDynamicFilter) : report.reportDetails.shareDynamicFilter;
          }

          // setting the label, start_date and end_date of filters
          if(report.reportDetails.shareReportTimeSelected && shareFilters.search.report_period){
            shareFilters.search.report_period.label = report.reportDetails.shareReportTimeSelected;
            if(shareFilters?.search?.report_period?.label.toLowerCase() === "custom date"){
              shareFilters.search.report_period.start_date = report.reportDetails.start_date;
              shareFilters.search.report_period.end_date = report.reportDetails.start_date;
            } else if(shareFilters?.search?.report_period?.label.toLowerCase() === "custom range"){
              shareFilters.search.report_period.start_date = report.reportDetails.start_date;
              shareFilters.search.report_period.end_date = report.reportDetails.end_date;
            } else{
              // incase of update just need to update the label.
              shareFilters.search.report_period.start_date = '';
              shareFilters.search.report_period.end_date = '';
            }
          }
          else if (report.reportDetails.shareReportTimeSelected ){
            // Should Run for Single-date Dynamic Reports
            shareFilters.search.report_date = report.reportDetails.start_date;
            
            shareFilters.search.report_period = {
              label: report.reportDetails.shareReportTimeSelected,
              start_date: report.reportDetails.start_date,
              end_date: null,
            };
          }

          // Auction List does not require time period
          if (
            report.report.name == "Auction List" &&
            report.report.original_collection_name == "Delinquency Reports"
          ) {
            reportPayloadObject.report_period.label = "";
            shareFilters.search.report_period.label = "";
            reportPayloadObject.label = "";
          }
          reportPayloadObject.filters =
            typeof shareFilters == "string" ? JSON.parse(shareFilters) : shareFilters;
          if (reportPayloadObject.format === "pdf") {
            reportPayloadObject.report_type = reportPayloadObject.type;
            reportPayloadObject.type = "generic";
            reportPayloadObject.format = "pdf";
            reportPayloadObject.filters =
              typeof shareFilters == "object"
                ? JSON.stringify(shareFilters)
                : shareFilters;
          }
          if (reportPayloadObject.format === "xlsx") {
            reportPayloadObject.filters =
              typeof shareFilters == "object"
                ? JSON.stringify(shareFilters)
                : shareFilters;
          }
        }
        reportPayloadObject.report_id = report.report.id;
        reportPayloadObject.collection_id = report.report.collection_id;
        return reportPayloadObject;
      });
      if (this.isEdit) {
        this.scheduleReportEditApiCall(shareReportObj);
      } else {
        this.scheduleReportApiCall(shareReportObj);
      }
    },
    scheduleReportApiCall(payload) {
      if (this.getScheduleType.length) {
        payload["schedule_type"] = this.getScheduleType;
      }
      api
        .post(this, api.REPOPRT_LIBRARY_SCHEDULED_REPORTS, {
          payload,
        })
        .then((r) => {
          if (r.hasOwnProperty("invalid_phones")) {
            EventBus.$emit("cancelMultipleCheckbox");
            EventBus.$emit("updateCheckedReport", []);
            this.$emit("closeShareSchedule");
            setTimeout(() => {
              const contNumber = r.invalid_phones.length > 1 ? "numbers" : "number";
              const text = r.invalid_phones.length > 1 ? "are" : "is";
              const reportCount = payload.reports.length > 1 ? "reports" : "report";
              this.showMessageNotification({
                description: `Below phone ${contNumber} ${text} invalid, please update the phone ${contNumber} to schedule the ${reportCount}`,
                list: r.invalid_phones,
                timeout: 15000,
              });
            }, 0);
          } else {
            EventBus.$emit("cancelMultipleCheckbox");
            EventBus.$emit("updateCheckedReport", []);
            this.$emit("closeShareSchedule");
            setTimeout(() => {
              this.showMessageNotification({
                type: "success",
                description: "The reports below have been scheduled.",
                list: payload.reports.map((report) => report.name),
              });
            }, 0);
          }
        })
        .catch((e) => {
          this.$emit("closeShareSchedule");
          setTimeout(() => {
            this.parseAndShowError(e, this.osrErrorDescription, this.osrErrorNote);
          }, 0);
        });
      this.loading = false;
      this.resetScheduleType();
    },
    parseAndShowError(err, description, note) {
      if(this.isJsonString(err))
      {
        let err_list = JSON.parse(err)
        this.showMessageNotification({
          description,
          list: err_list,
          note,
          persistent: true
        });
      }
      else {
          this.showMessageNotification({
            description: err
          });
      }
    },
    isJsonString(value) {
      try {
        JSON.parse(value);
        return true;
      }
      catch(err) {
        return false
      }
    },
    scheduleReportEditApiCall(payload) {
      api
        .put(
          this,
          api.REPOPRT_LIBRARY_SCHEDULED_REPORTS + "/" + this.scheduleInfo.scheduleId,
          { payload }
        )
        .then((r) => {
          if (r.hasOwnProperty("invalid_phones")) {
            this.$emit("closeShareSchedule");
            setTimeout(() => {
              const contNumber = r.invalid_phones.length > 1 ? "numbers" : "number";
              const text = r.invalid_phones.length > 1 ? "are" : "is";
              const reportCount = payload.reports.length > 1 ? "reports" : "report";
              this.showMessageNotification({
                description: `Below phone ${contNumber} ${text} invalid, please update the phone ${contNumber} to schedule the ${reportCount}`,
                list: r.invalid_phones,
                timeout: 15000,
              });
            }, 0);
          } else {
            this.$emit("closeShareSchedule");
            setTimeout(() => {
              this.showMessageNotification({
                type: "success",
                description: "Your changes have been saved.",
              });
            }, 0);
          }
          this.$emit("fetchUpdatedData");
        })
        .catch((e) => {
          this.$emit("closeShareSchedule");
          this.showMessageNotification({
            type: "error",
            list: e,
          });
        });
      this.loading = false;
    },
    removeLastReport() {
      this.removeLastReportFlag = true;
    },
    removeViewReport(id) {
      this.$emit("removeViewScheduleReport", id);
    },
    closeViewEditModal() {
      this.$emit("close");
      this.resetScheduleType();
    },
    removeReportShare(report) {
      this.checkedReportsShare = this.checkedReportsShare.filter(
        (cReport) => cReport.report.id !== report.id
      );
    },
  },
  watch: {
    deliverySetupReportName() {
      this.deliverySetupReportName.length
        ? (this.errorList = this.errorList.filter(
            (item) => item != "The Setup Repeat Delivery field is required"
          ))
        : "";
      !this.deliverySetupReportName.length && !this.deliverySetupReportName.length > 256
        ? (this.errorList = this.errorList.filter(
            (item) =>
              item !=
              "The Setup Repeat Delivery field may not be greater than 256 characters"
          ))
        : "";
      let message = {
        list: this.errorList,
        persistent: true,
      };
      if (message.list.length) {
        this.showMessageNotification(message);
      } else {
        this.closeMessageNotification();
      }
    },
    propertyArray() {
      this.propertyArray.length
        ? (this.errorList = this.errorList.filter(
            (item) => item != "The Property field is required"
          ))
        : "";
      let message = {
        list: this.errorList,
        persistent: true,
      };
      if (message.list.length) {
        this.showMessageNotification(message);
      } else {
        this.closeMessageNotification();
      }
    },
    scheduledFrequency() {
      this.scheduledFrequency.length
        ? (this.errorList = [
            ...this.errorList.filter(
              (item) => item != "The Schedule Frequency field is required"
            ),
          ])
        : "";
      let message = {
        list: this.errorList,
        persistent: true,
      };
      if (message.list.length) {
        this.showMessageNotification(message);
      } else {
        this.closeMessageNotification();
      }
    },
    CoworkersArray() {
      let emailsList = [];
      this.emailRecipientArray.forEach((el) =>
        el.email.length ? emailsList.push(el.email) : ""
      );
      let plus = "+";
      let smsList = [];
      this.smsRecipientArray.forEach((el) =>
        el.code.length && el.phone.length
          ? smsList.push(plus.concat(el.code.concat(el.phone.replace(/[^0-9]/g, ""))))
          : ""
      );
      this.CoworkersArray.length || emailsList.length || smsList.length
        ? (this.errorList = this.errorList.filter(
            (item) => item != "The Recipient field is required"
          ))
        : "";
      let message = {
        list: this.errorList,
        persistent: true,
      };
      if (message.list.length) {
        this.showMessageNotification(message);
      } else {
        this.closeMessageNotification();
      }
    },
    emailRecipientArray() {
      let emailsList = [];
      this.emailRecipientArray.forEach((el) =>
        el.email.length ? emailsList.push(el) : ""
      );
      let plus = "+";
      let smsList = [];
      this.smsRecipientArray.forEach((el) =>
        el.code.length && el.phone.length
          ? smsList.push(plus.concat(el.code.concat(el.phone.replace(/[^0-9]/g, ""))))
          : ""
      );

      this.CoworkersArray.length || emailsList.length || smsList.length
        ? (this.errorList = [
            ...this.errorList.filter((item) => item != "The Recipient field is required"),
          ])
        : "";

      let message = {
        list: this.errorList,
        persistent: true,
      };

      if (message.list.length) {
        this.showMessageNotification(message);
      }
    },
    smsRecipientArray() {
      let emailsList = [];
      this.emailRecipientArray.forEach((el) =>
        el.email.length ? emailsList.push(el.email) : ""
      );
      let plus = "+";
      let smsList = [];
      this.smsRecipientArray.forEach((el) =>
        el.code.length && el.phone.length
          ? smsList.push(plus.concat(el.code.concat(el.phone.replace(/[^0-9]/g, ""))))
          : ""
      );

      this.CoworkersArray.length || emailsList.length || smsList.length
        ? (this.errorList = [
            ...this.errorList.filter((item) => item != "The Recipient field is required"),
          ])
        : "";

      let message = {
        list: this.errorList,
        persistent: true,
      };
      if (message.list.length) {
        this.showMessageNotification(message);
      }
    },
    fileArr() {
      if (!this.fileArr.length) {
        this.errorList = [
          ...this.errorList.filter((item) => item != "The File Type field is required"),
        ];
        let message = {
          list: this.errorList,
          persistent: true,
        };
        if (message.list.length) {
          this.showMessageNotification(message);
        }
      }
    },
    errorList() {
      if (!this.errorList.length) {
        this.closeMessageNotification();
      }
    },
  },
  destroyed() {
    this.closeMessageNotification();
  },
};
</script>

<style lang="scss" scoped>
.capitalize {
  text-transform: capitalize;
}
.text-grey {
  color: grey;
}
.align-right {
  float: right;
}
.email-recipients-first {
  margin-top: 2px !important;
  width: 370px !important;
}
.email-recipients-others {
  width: 324px !important;
}
.phone-number-first {
  width: 306px !important;
}
.phone-number-others {
  width: 263px !important;
}
.share-combobox {
  height: 40px !important;
  overflow-y: scroll;
  overflow-x: hidden;
}
.co-workers-top {
  margin-top: 30px !important;
}

.email-text-field-wrapper .v-label {
  color: #637381 !important;
  font-size: 16px !important;
  font-weight: 400 !important;
}
.sms-recipient,
.email-recipients {
  color: #637381 !important;
  font-size: 11px !important;
  margin-right: 1px !important;
  margin-bottom: 0px !important;
}
.sms-modal-recipient,
.email-modal-recipient {
  margin-bottom: 7px !important;
}

.email-text-field-wrapper .v-input:not(.v-input--is-dirty) ::placeholder {
  color: #637381 !important;
  font-size: 14px !important;
}
.email-text-field-wrapper >>> .v-input.error--text input,
.email-text-field-wrapper >>> .v-input.error--text label,
.hb-aviary-form-row >>> .v-label.v-label--active.error--text,
.email-text-field-wrapper .v-input.v-text-field--placeholder.error--text ::placeholder {
  color: #fb4c4c !important;
}
.email-text-field-wrapper >>> .hb-aviary-form-row >>> .v-input.error--text label,
.v-label.v-label--active.error--text {
  color: #637381 !important;
}
.share-report-modal {
  ::v-deep .v-expansion-panel-content__wrap {
    padding: 0px !important;
  }
}
.coworker-autocomplete-wrapper >>> .v-input.error--text input,
.coworker-autocomplete-wrapper >>> .v-input.error--text label,
.hb-aviary-form-row >>> .v-label.v-label--active.error--text,
.coworker-autocomplete-wrapper
  .v-input.v-text-field--placeholder.error--text
  ::placeholder {
  color: #fb4c4c !important;
}
.coworker-autocomplete-wrapper >>> .v-input input,
.coworker-autocomplete-wrapper >>> .v-input label,
.hb-aviary-form-row >>> .v-label.v-label--active,
.coworker-autocomplete-wrapper .v-input.v-text-field--placeholder ::placeholder {
  color: #637381 !important;
}

.coworker-autocomplete-wrapper >>> .v-input.error--text input,
.coworker-autocomplete-wrapper >>> .v-input.error--text label,
.hb-aviary-form-row >>> .v-label.v-label--active.error--text,
.coworker-autocomplete-wrapper
  .v-input.v-text-field--placeholder.fielderror
  ::placeholder {
  color: #fb4c4c !important;
}
.co-worker-properties
  .v-input.pill-chip-wraper.theme--light.v-text-field.v-text-field--is-booted.v-text-field--placeholder.v-select.v-select--is-multi.v-autocomplete.propertyError
  .v-input__control
  .v-input__slot::before {
  border-color: #fb4c4c !important;
}
.co-worker-properties
  .v-input.pill-chip-wraper.theme--light.v-text-field.v-text-field--is-booted.v-text-field--placeholder.v-select.v-select--is-multi.v-autocomplete.propertyError
  ::placeholder {
  color: #fb4c4c !important;
}

// prop-dropdown-wrapper

.coworker-div-wrapper {
  margin-bottom: 7px !important;
}
.phone-div {
  margin-bottom: 0px !important;
}
.select-all-background {
  background-color: #e0f2f1 !important;
}
.email-row-first {
  margin-top: 20px !important;
}
.schedule-field-wrapper .v-input:not(.v-input--is-dirty) ::placeholder {
  color: #637381 !important;
  font-size: 14px !important;
  opacity: 1;
}

.design-system-adherence-370-max-width-input {
  max-width: 370px;
}

.design-system-adherence-list-item-height .v-list-item {
  min-height: 32px !important;
}
</style>
<style>
.report-share-modal-wrapper .hb-aviary-form-input-370-max-width {
  max-width: 538px !important;
}
body .hb-aviary-form-slot-wrapper div.repeat-form-container {
  margin-bottom: 0px;
}
body .hb-aviary-form-slot-wrapper .reports-container .share-report-modal {
  margin-bottom: 0px;
}
body .hb-aviary-form-slot-wrapper .reports-container .share-report-modal > div {
  margin-bottom: 8px;
}
body
  .hb-aviary-form-slot-wrapper
  .reports-container
  .share-report-modal
  > div:last-child {
  margin-bottom: 10px;
}
.co-worker-properties .v-text-field__details {
  display: none;
}
.co-worker-properties .v-input__slot {
  margin-bottom: 0px;
}
body .coworker-autocomplete-wrapper.co-worker-properties div.pill-chip-wraper,
.coworker-autocomplete-wrapper.coworker-div-wrapper div.pill-chip-wraper {
  padding-top: 8px;
}
body .coworker-autocomplete-wrapper.coworker-div-wrapper div.pill-chip-wraper .v-label {
  top: 1px;
}
.pill-chip-wraper .v-input__control .v-input__slot {
  padding-bottom: 8px;
}

.report-share-modal-wrapper .v-input.col-8.v-text-field--placeholder.fieldError
  .v-text-field__slot
  input::placeholder {
  color: red !important;
}
.report-share-modal-wrapper .v-input.col-8.v-text-field--placeholder.fieldError
  .v-select__selections
  input::placeholder {
  color: red !important;
}
</style>
