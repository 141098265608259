<template>
  <div>
    <v-row style="background: #F9FAFB;" no-gutters>
      <v-col :cols="5" class="pb-0 mt-3">
        <HbAutocomplete
          search
          :append-icon="searchItems.length == 0 ? '' :'mdi-menu-down'"
          @keyup="setSearchParams"
          @keydown.enter="searchSelectByEnter"
          @click:clear="clearSearchItems"
          @change="searchSelect"
          @focus="clear"
          v-model="searchField"
          :items="searchItems"
          full
          :no-data-text="'No Reports Found'"
          :hide-no-data="(hideNoData && searchItems.length == 0)? true:false"
          item-text="name"
          item-value="id"
          return-object
          id="report-search"
          placeholder="Search"
          width="525px"
        />
      </v-col>
      <v-col :cols="7" align="right" class="share-home-report-btn">
      <hb-btn
        v-if="hasPermission('share_schedule_reports')"
        color="secondary"
        class="ml-1"
        style="position:relative;top:11px;"
        @click="toggleShare(true)"
        :disabled="share"
        small
      >
        <div class="d-flex align-center">
          <hb-icon class="pr-1 ml-n1" small>mdi-user-actions-custom-1</hb-icon>
          Share Reports
        </div>
      </hb-btn>
      </v-col>
    </v-row>
    <div class="primary-section-content mb-2" v-show="share || addReport" :class="shareOption && 'stick-on-top'">
      <share-accordion
        @close="toggleShare(false)"
        :shareReport="shareReport"
        :type="shareType"
        :selectedCount="checkedReports.length"
        @openMultipleShareModal="openShareModal"
        :isAddReport="addReport"
        :collectionType="collection_type"
        @addReportApiCall="addReportApiCall"
      ></share-accordion>
    </div>
    <report-share-modal
        v-if="openMultipleShare"
        v-model="openMultipleShare"
        :MultipleType="shareType"
        @close="openMultipleShare = false"
        :checkedReports="checkedReports"
        @closeShareSchedule="openMultipleShare = false"
      >
    </report-share-modal>
    <div class="primary-section-content">
      <collection-accordion
        :checkedCollections="getCheckedCollectionIds"
        :checkedCollectionsName="getCheckedCollectionName"
        :collectionScrollId="collectionScrollId"
        :reportScrollId="reportScrollId"
        :closeAllCollections="closeAllCollections"
        :share="shareOption"
        :errorName='fileArr'
        @checkAll="checkAll"
        @handleDropdownFocus='handleDropdownFocus'
        @setAddReport="setAddReport"
      ></collection-accordion>
    </div>
  </div>
</template>
<script type="text/babel">
import CollectionAccordion from "./CollectionAccordion";
import ReportShareModal from "./Common/ReportShareModal.vue";
import ShareAccordion from "./ShareAccordion";
import { notificationMixin } from "@/mixins/notificationMixin.js";
import { mapGetters, mapMutations, mapActions } from "vuex";
import api from "../../assets/api.js";
import { EventBus } from "../../EventBus";

export default {
  name: "ReportLibrary",
  mixins: [notificationMixin],
  data() {
    return {
      searchField: "",
      searchItems: [],
      searchItemsByEnter: [], 
      searchedValue: "",     
      collectionScrollId: "",
      reportScrollId: "",
      loading: false,
      closeAllCollections: false,
      share: false,
      shareType: null,
      selectedReports: [],
      checkedReports: [],
      shareOption:false,
      hideNoData:true,
      openMultipleShare: false,
      fileArr:[],
      collection_type: null,
      addReport: false
    };
  },
  components: {
    CollectionAccordion,
    ShareAccordion,
    ReportShareModal
  },
  computed: {
    collections() {
      let collection =   this.$store.getters["reportLibraryStore/collectionStore"];
    if(!this.hasPermission('mini_charm')){
          collection = collection.filter(item => item.name !== "Charm Reports")
    }
      return collection
    },
    getCheckedCollectionIds() {
      return [...new Set(this.checkedReports.map(item => item.report.id))];
    },
    getCheckedCollectionName() {
      return [...new Set(this.checkedReports.map(item => item.report.collection_name))];
    },
    ...mapGetters({
      addReportCollection: 'reportLibraryStore/getAddReportCollection',
      customCollReports: 'reportLibraryStore/getCustomCollReports',
      getActiveDynamicReport: "reportStore/getActiveDynamicReport",
    })
  },
  created() {
    EventBus.$on("fetchCheckboxId", this.captureCheckedReports);
    EventBus.$on("updateCheckedReport", this.updateCheckedReport);
    this.setAddReportCollection(null);
    if(this.getActiveDynamicReport?.id) {
      this.searchSelectOnBackToAllReports();
    }
  },
  destroyed() {
    if(!this.$route.path.includes('/reports-library')) this.updateActiveDynamicReport({});
    EventBus.$off("fetchCheckboxId", this.captureCheckedReports);
    EventBus.$off("updateCheckedReport", this.updateCheckedReport);
  },
  methods: {
     ...mapActions({
      addReportCollectionStore: "reportLibraryStore/addReportCollectionStore",
      updateActiveDynamicReport: "reportStore/updateActiveDynamicReport",
    }),
    ...mapMutations({
      setAddReportCollection: 'reportLibraryStore/setAddReportCollection'
    }),
     openShareModal() {
      this.fileArr=[];
      this.checkedReports.forEach((item)=>{
         const {reportDetails} = item
         if(!reportDetails.file_type){
             this.fileArr.push(item.report.name);
         }
      })
      if(this.fileArr.length){
        return this.showMessageNotification({
          description: "Please enter all the fields for the below reports",
          list: this.fileArr,
        });
      }else{
        this.openMultipleShare = true
      }

    },

    handleDropdownFocus(data){
      this.$nextTick(() => {
         this.fileArr=this.fileArr.filter(function(item) {
             return item !== data
          })
      });
    },
   
    captureCheckedReports(report, flag) {
      let isObjectPresent = this.checkedReports.find(
        (obj) => obj.report.id === report.report.id
      );
      if (flag) {
        if (!isObjectPresent) {
          // not present - push
          this.checkedReports.push(report);
        } else {
          // present - update
          isObjectPresent.reportDetails = report.reportDetails;
        }
      } else {
        const index = this.checkedReports.findIndex(
          (obj) => obj.report.id === report.report.id
        );
        if(index !== -1) {
          this.checkedReports.splice(index, 1);
        }
      }
    },
    setSearchParams(event) {
      if (event.keyCode === 13 || event.keyCode === 38 || event.keyCode === 40) {
        event.preventDefault(); // prevent calling the function on ENTER key press or UP DOWN Arrow Key
        return;
      }
      if (event.target._value.length) {
        this.searchedValue = event.target._value;
        this.hideNoData = false;
        this.collectionScrollId = "";
        this.reportScrollId = "";
        api.get(this, api.SEARCH_REPORT + event.target._value).then(r => {
          this.searchItems = r.reports;
          this.searchItemsByEnter = r.reports;
        });
      } else {
        this.searchItems = [];
        this.searchItemsByEnter = [];
        this.searchField = "";
        this.hideNoData = true;
      }
    },
    clearSearchItems() {
      this.searchItems = [];
      this.searchItemsByEnter = [];
      this.searchField = "";
      this.closeAllCollections = false;
      this.hideNoData = true;
    },
    searchSelect() {
      if (this.searchField.hasOwnProperty("id")) {
        this.collectionScrollId = this.searchField.collection_id;
        this.reportScrollId = this.searchField.id;
        this.closeAllCollections = false;
        let glb = this;
        this.loading = true;
        let loop = setInterval(() => {
          clearInterval(loop);
            EventBus.$emit('open-panel',glb.reportScrollId);
            let loopInner = setInterval(() => {
            let element = document.getElementById('href' + this.searchField.id + this.collectionScrollId);
            if(element) {
              clearInterval(loopInner)
              let isMultipleorAdd;
              if (this.shareType ){
                isMultipleorAdd = -310
              }else if (this.addReportCollection) {
                isMultipleorAdd = -190
              }
              setTimeout(function () {
                  const yOffset = isMultipleorAdd || -68;
                  const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                  window.scrollTo({top: y, behavior: 'auto'});
              }, 500);
            }
            }, 200);
        }, 200);
      }
    },
    searchSelectOnBackToAllReports() {
      this.collectionScrollId = this.getActiveDynamicReport?.collection_id;
      this.reportScrollId = this.getActiveDynamicReport?.id;
      this.closeAllCollections = false;
      let glb = this;
      this.loading = true;
      let loop = setInterval(() => {
        clearInterval(loop);
          EventBus.$emit('open-panel',glb.reportScrollId);
          let loopInner = setInterval(() => {
          let element = document.getElementById('href' + this.getActiveDynamicReport?.id + this.getActiveDynamicReport?.collection_id);
          if(element) {
            clearInterval(loopInner)
            let isMultipleorAdd;
            if (this.shareType ){
              isMultipleorAdd = -310
            }else if (this.addReportCollection) {
              isMultipleorAdd = -190
            }
            setTimeout(function () {
                const yOffset = isMultipleorAdd || -68;
                const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                window.scrollTo({top: y, behavior: 'auto'});
            }, 500);
          }
          }, 200);
      }, 200);
    },
    
    searchSelectByEnter() {
      let retrievedValueName = this.searchItemsByEnter[0].name.toLowerCase();
      if(retrievedValueName !== this.searchedValue.toLowerCase()){
        return;
      }   
      this.searchField = this.searchItemsByEnter[0];
      document.getElementsByClassName('hb-page-header-description')[0].click(); // just to focus out from the search input
      
      if(this.searchField.hasOwnProperty("id")){
        this.collectionScrollId = this.searchField.collection_id;
        this.reportScrollId = this.searchField.id;
        this.closeAllCollections = false;
        let glb = this;
        this.loading = true;
        let loop = setInterval(() => {
          clearInterval(loop);
            EventBus.$emit('open-panel',glb.reportScrollId);
            let loopInner = setInterval(() => {
            let elem_id = "href" + this.searchField.id + this.searchField.collection_id;            
            let element = document.getElementById(elem_id);
            if(element) {
              clearInterval(loopInner)
              let isMultipleorAdd;
              if (this.shareType ){
                isMultipleorAdd = -310
              }else if (this.addReportCollection) {
                isMultipleorAdd = -190
              }
              setTimeout(function () {
                  const yOffset = isMultipleorAdd || -68;
                  const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                  window.scrollTo({top: y, behavior: 'auto'});
              }, 500);
            }
            }, 200);
        }, 200);
      }
    },
    toggleShare(share) {
      this.addReport = false;
      this.share = share;
      this.checkedReports = [];
      this.setAddReportCollection(null);
    },
    setAddReport(collection_id, collection_type){
      this.addReport = true;
      this.share = false;
      this.shareType = null;
      this.collection_type = collection_type;
      this.collection_id = collection_id;
      this.shareOption = true;
    },

    addReportApiCall() {
      let reportIds = this.checkedReports.map(report => {
        return { 'report_id': report.report.id };
      })
      let payload = {
        "collection_id": this.collection_id,
        "collection_type": this.collection_type  == "Bankers Box" ? "banker_box" : "investor_reports",
        "reports": reportIds
      }
      api.post(this, api.ADD_REPORT, payload).then(res => {
        this.addReportCollectionStore({'reports': this.checkedReports, 'collectionType': this.collection_type});
        let reportsLength = this.checkedReports.length;
        this.showMessageNotification({
            type: "success",
            description:
              `You have added 
              ${reportsLength} Report${reportsLength > 1 ? 's': ''} to the ${this.collection_type}.`,
              list: this.checkedReports.map(report => report.report.name)
          });
        this.checkedReports = [];
        EventBus.$emit('cancelMultipleCheckbox');
        this.addReport = false;
        this.shareOption = false;
        this.setAddReportCollection(null);
      })
      .catch(err => {
        this.addReport = false;
        this.setAddReportCollection(null);
        this.showMessageNotification({ type: "error", description: err });
      })
    },
    shareReport(option) {
      if(option){
        this.shareOption = true;
      }else{
         this.shareOption = false;
         this.addReport = false;
         this.setAddReportCollection(null);
         this.cancelMultipleShare();
         EventBus.$emit('cancelMultipleCheckbox');
      }
      this.shareType = option;
    },
    checkAll(colId, value) {
      let collection = this.collections.find(
        (obj) => obj.id === colId
      );
      let alreadyAddedReport = false;
      collection.reports.forEach(report => {
        report.collection_name = collection.name;
        alreadyAddedReport = false;
        if(this.addReportCollection) {
          if (this.customCollReports[this.addReportCollection] && this.customCollReports[this.addReportCollection].includes(report.id)) {
            alreadyAddedReport = true;
          }
        }
        if (!alreadyAddedReport) {
          let reportDetails = report.reportDetails ? report.reportDetails : {};
          if(report.report_category === "static") {
            if (!report.download_xls) { 
              if(!reportDetails.file_type) {
                reportDetails.file_type = 'pdf';
              }
              let timePeriod = report.end_date ? "This Month" : "Today"
              reportDetails.shareReportTimeSelected = reportDetails.shareReportTimeSelected || timePeriod;
            }
          }
        // reportDetails={...report.reportDetails,shareReportTimeSelected:report.end_date ? "This Month" : "Today"}
        this.captureCheckedReports({reportDetails, report}, value);
        }
      });
    },
    cancelMultipleShare() {
      this.checkedReports.splice(0);
    },
    updateCheckedReport(value) {
      this.checkedReports = value;
    }
  }
};
</script>
<style  lang="scss" scoped>
.report-search input {
  padding-bottom: 0px !important;
}
.report-search .v-input__control {
  height: 40px !important;
}
/*
.v-text-field--enclosed.v-input--dense:not(.v-text-field--solo).v-text-field--outlined
  .v-input__prepend-inner {
  margin-top: 12px;
}
*/

// .v-input__icon--clear {
//   margin-top: 8px;
// }
.v-text-field--outlined > .v-input__control > .v-input__slot {
  background: #ffffff;
}
.justify-content-between {
  justify-content: space-between;

}
.report-search   .v-list-item--dense .v-list-item__content, .v-list--dense .v-list-item .v-list-item__content {
    text-transform: none;
  }

// #report-search  .v-menu__content .theme--light .menuable__content__active .v-autocomplete__content .hb-autocomplete-menu {
//     z-index: 4 !important;
// }

.hb-autocomplete-wrapper .v-input__append-inner {
  position:relative;
  top: -2px !important;
}

.share-home-report-btn{
  margin-top: 12px;
}

.stick-on-top{
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 65px;
  z-index: 5;
  padding-top: 1px;
}

.stick-on-top::before {
    content: '';
    position: absolute;
    width: calc(100% / 2 * 12);
    height: 125px;
    background: #f9fafb;
    left: -19px;
    top: 0;
}

</style>
