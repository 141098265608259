<template>
  <div
    class="section-content pt-4 report-sub-heading"
    :class="{
      'mt-10': $vuetify.breakpoint.xs,
      'mt-n3': $vuetify.breakpoint.sm
    }"
  >
    <hb-header full :divider="false">
      <hb-page-header
        title="Reports Library"
        :description="tabDescription[activeTab] ?? ''"
      >
      </hb-page-header>
    </hb-header>
    <hb-header fewer-actions :padding="false" align-top>
      <template v-slot:left>
        <hb-tabs v-model="activeTab">
          <v-tab
            v-for="menuOption in menuOptions"
            :key="menuOption.key"
            :href="'#' + menuOption.key"
            @click="view = menuOption.key"
            :ripple="false"
            >{{ menuOption.label }}</v-tab
          >
        </hb-tabs>
      </template>
    </hb-header>
    <div class="primary-section-content">
      <v-sheet
        class="grey lighten-5"
        style="width: calc(100%);"
      >
        <report-library :key="report_key" v-if="view === 'reportLibrary'"></report-library>
        <view-scheduled-reports :key="report_key" v-if="view === 'viewScheduledReports'"></view-scheduled-reports>
        <reports-download-history :key="report_key" v-if="view === 'reportsDownloadHistory'"></reports-download-history>
      </v-sheet>
    </div>
    <v-overlay :value="reportLoading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script type="text/babel">
import Header from "../header/Index.vue";
import Loader from "../assets/CircleSpinner.vue";
import ReportLibrary from "./ReportLibrary";
import ViewScheduledReports from "./ViewScheduledReports";
import ReportsDownloadHistory from "./ReportsDownloadHistory.vue";
import { EventBus } from '../../EventBus.js';
import { mapActions, mapGetters } from "vuex";

export default {
  name: "ReportsIndex",
  data() {
    return {
      view: "reportLibrary",
      activeTab: "reportLibrary",
      menuOptions: [
        {
          key: "reportLibrary",
          label: "Report Library"
        },
        {
          key: "reportsDownloadHistory",
          label: "Reports Download History"
        },
      ],
      tabDescription: {
        reportLibrary: "View, Share and Setup Repeat Delivery Schedules from the Report Library.",
        viewScheduledReports: "See everything you need to know about your scheduled reports. You can also make changes or delete scheduled reports if necessary.",
        reportsDownloadHistory: "View and download the reports that you have generated in last 60 Days."
      },
      reportLoading: false,
      report_key:0
    };
  },
  components: {
    Loader,
    Header,
    ReportLibrary,
    ViewScheduledReports,
    ReportsDownloadHistory,
  },
  async created(){
      EventBus.$on('refetch_data', () => this.fetchData());
      if (!this.fiscalYearMonth) {
        await this.fetchFiscalYearMonth();
      }

      if(this.hasPermission('view_scheduled_reports')) {
        this.menuOptions.splice(1, 0, {
          key: "viewScheduledReports",
          label: "View Scheduled Reports"
        })
      }
  },
  destroyed(){
      EventBus.$off('refetch_data', () => this.fetchData());
  },
  methods: {
    ...mapActions({
        fetchFiscalYearMonth: "settingsStore/fetchFiscalYearMonth",
    }),
    fetchData(){
      this.report_key++;
    },
  },
  computed: {
    ...mapGetters({
        fiscalYearMonth: "settingsStore/fiscalYearMonth"
    }),
  }
};
</script>
<style scoped lang="scss">
.section-content {
  background: rgb(249, 250, 251);
}
.custom-border-bottom {
  border-bottom: 1px solid lightgray;
}
.primary-section-content {
  display: flex;
  height: calc(100% - 70px);
}
.attached-drawer {
  height: auto;
  padding: 0;
  width: 0;

  &.theme--light {
    &.v-navigation-drawer {
      background-color: none;
    }
  }
  &.attached-drawer--active {
    width: 400px;
  }
}

.no-drag {
  background: rgba(0, 0, 0, 0.1);
}
.dashboard-options {
  cursor: grab;
  .no-drag {
    cursor: not-allowed;
  }
}
.subHeading {
  font-size: 14px;
  vertical-align: top;
  letter-spacing: -0.2px;
  align-content: left;
  margin: 0px;
}
.main-section-heading {
  padding: 29px 20px 0px 0px !important;
  border: none !important;
}
</style>
<style>
.report-sub-heading .hb-header-font-size +div{
  color: #637381;
}

body header.v-app-bar.v-app-bar--fixed {
  z-index: 50;
}
</style>
